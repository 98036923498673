import { PractitionerCard } from "@cruncho/cruncho-shared-types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { DeletePractitionerDialog } from "./DeletePractitionerDialog";
import { EditStatusPractitionerDialog } from "./EditStatusPractitionerDialog";

type PractitionersListItemProps = {
	practitioner: PractitionerCard;
};

export function PractitionersListItem({
	practitioner,
}: PractitionersListItemProps) {
	const navigate = useNavigate();

	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const handleDeleteModal = () => {
		setOpenDeleteModal(!openDeleteModal);
	};
	const [openEditRoleModal, setOpenEditRoleModal] = useState<boolean>(false);
	const handleEditRoleModal = () => {
		setOpenEditRoleModal(!openEditRoleModal);
	};

	return (
		<>
			<ListItemButton>
				{practitioner ? (
					<ListItemText
						primary={`${practitioner.firstname} ${practitioner.lastname}`}
						secondary={practitioner.type}
					/>
				) : (
					<ListItemText primary="ALL" />
				)}
				{practitioner && (
					<div className="flex flex-row gap-5">
						{practitioner.verifiedStatus === "approved"
							? "labelled_ok"
							: "no_label_yet"}
						<IconButton
							title="Edit status"
							aria-label="edit status"
							edge="end"
							onClick={handleEditRoleModal}
							size="large"
						>
							<MoreVertIcon />
						</IconButton>
						<IconButton
							title="Edit the data of this practitioner"
							aria-label="edit practitioner"
							edge="end"
							onClick={() => {
								navigate(`/practitioner/${practitioner._id}`);
							}}
							size="large"
						>
							<EditIcon />
						</IconButton>
						<IconButton
							title="Delete this practitioner"
							aria-label="delete practitioner"
							edge="end"
							onClick={handleDeleteModal}
							size="large"
						>
							<DeleteIcon />
						</IconButton>
					</div>
				)}
			</ListItemButton>
			<DeletePractitionerDialog
				onClose={handleDeleteModal}
				open={openDeleteModal}
				practitioner={practitioner}
			/>
			<EditStatusPractitionerDialog
				onClose={handleEditRoleModal}
				open={openEditRoleModal}
				practitioner={practitioner}
			/>
		</>
	);
}
