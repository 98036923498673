import {
	PractitionerCard,
	VERIFIED_STATUS,
} from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { api } from "services/api";

type EditStatusPractitionerDialogProps = {
	onClose: () => void;
	open: boolean;
	practitioner: PractitionerCard;
};

export function EditStatusPractitionerDialog({
	onClose,
	open,
	practitioner,
}: EditStatusPractitionerDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const onEditClick = async (newStatus: VERIFIED_STATUS) => {
		try {
			practitioner.verifiedStatus = newStatus;
			const res = await api.practitioner.updatePractitioner(
				practitioner._id!,
				practitioner,
			);

			if (res)
				enqueueSnackbar(`${practitioner.firstname} edited! - ${res}`, {
					variant: "success",
				});
		} catch (error: any) {
			console.error(error);
			if (
				axios.isAxiosError(error) &&
				(error as AxiosError<{ message: string }>).response?.data?.message
			) {
				enqueueSnackbar(
					(error as AxiosError<{ message: string }>).response?.data?.message,
					{
						variant: "error",
						persist: true,
					},
				);
			} else {
				enqueueSnackbar(JSON.stringify(error, null, 2), {
					variant: "error",
					persist: true,
				});
			}
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>Change the status of this practitioner ?</DialogTitle>
			<DialogContent>
				{`Do you really want to edit the status of the following practitioner: ${practitioner.firstname} ${practitioner.lastname} ?`}
			</DialogContent>
			<DialogContent>
				{`Company name: ${practitioner.companyName} // Company number: ${practitioner.companyNumber}`}
			</DialogContent>
			<DialogActions>
				{practitioner.verifiedStatus !== "approved" && (
					<Button
						type="submit"
						color="secondary"
						variant="outlined"
						onClick={() => onEditClick("approved")}
					>
						Approve
					</Button>
				)}
				{practitioner.verifiedStatus === "approved" && (
					<Button
						type="submit"
						color="secondary"
						variant="outlined"
						onClick={() => onEditClick("no_request")}
					>
						Reject
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
