import { PractitionerCard } from "@cruncho/cruncho-shared-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { api } from "services/api";
import CopyPractitionersEmail from "./CopyPractitionersEmail";
import { PractitionersListItem } from "./PractitionersListItem";

export function PractitionersList() {
	/**
	 * A string used to filter the displayed categories
	 */
	const [showCopyPractitioners, setShowCopyPractitioners] = useState(false);

	const [searchQuery, setSearchQuery] = useState<string>("");

	const [practitioners, setPractitioners] = useState<PractitionerCard[]>([]);

	/**
	 * The subset of practitioners kept while filtering
	 */
	const [filteredPractitioners, setFilteredPractitioners] =
		useState(practitioners);

	const [onlyPending, setOnlyPending] = useState<boolean>(false);

	const fetchPractitioners = async (): Promise<PractitionerCard[]> => {
		const result = await api.practitioner.getAll();
		return result;
	};

	useEffect(() => {
		fetchPractitioners().then((fetchedPractitioners) =>
			setPractitioners(fetchedPractitioners),
		);
	}, []);

	useEffect(() => {
		if (searchQuery) {
			if (onlyPending) {
				setFilteredPractitioners(
					practitioners.filter(
						(practitioner) =>
							(practitioner.firstname?.includes(searchQuery) ||
								practitioner.lastname?.includes(searchQuery)) &&
							practitioner.verifiedStatus !== "approved",
					),
				);
			} else {
				setFilteredPractitioners(
					practitioners.filter(
						(practitioner) =>
							practitioner.firstname?.includes(searchQuery) ||
							practitioner.lastname?.includes(searchQuery),
					),
				);
			}
		} else if (onlyPending) {
			setFilteredPractitioners(
				practitioners.filter(
					(practitioner) => practitioner.verifiedStatus !== "approved",
				),
			);
		} else {
			setFilteredPractitioners(practitioners);
		}
	}, [practitioners, searchQuery, onlyPending]);

	return (
		<Card style={{ maxHeight: "70vh", overflow: "auto" }}>
			<CardHeader
				title="Practitioners"
				action={<Typography>{filteredPractitioners.length}</Typography>}
				style={{
					position: "sticky",
					top: 0,
					backgroundColor: "white", // Set a background color if needed
					zIndex: 1000, // Adjust the z-index to make sure it's above other elements
				}}
			/>
			<CardContent>
				{practitioners.length > 0 && (
					<>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setShowCopyPractitioners(true);
							}}
							className="mb-4"
						>
							Get Practitioners&apos; Email
						</Button>

						<CopyPractitionersEmail
							users={practitioners ?? []}
							show={showCopyPractitioners}
							handleClose={() => setShowCopyPractitioners(false)}
						/>
						<TextField
							fullWidth
							label="Search"
							onChange={(event) => {
								// keeping track of the current search query
								setSearchQuery(event.target.value);
							}}
							value={searchQuery}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: searchQuery && (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setSearchQuery("")}
											size="large"
											aria-label="clear"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<TextField
							type="checkbox"
							label="Pending"
							onChange={() => {
								setOnlyPending(!onlyPending);
							}}
						/>
						<span className="ml-3">Pending only</span>
					</>
				)}
				{filteredPractitioners.length > 0 ? (
					<List>
						{filteredPractitioners.map((practitioner) => (
							<PractitionersListItem
								key={practitioner.userId}
								practitioner={practitioner}
							/>
						))}
					</List>
				) : (
					<Box margin={2}>
						<Typography variant="body2" color="textSecondary">
							No practitioners available
						</Typography>
					</Box>
				)}
			</CardContent>
		</Card>
	);
}
