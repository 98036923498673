import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as Routerlink, useNavigate } from "react-router-dom";
import { paths } from "./routes";
import { api, currentEnvironment } from "./services/api";

/**
 * The main header nav bar
 */
function MainNav() {
	const navigate = useNavigate();

	const logout = () => {
		api.auth.logout();
		navigate(paths.login);
	};

	return (
		<div>
			<AppBar position="fixed">
				<Toolbar>
					<div className="flex items-center justify-between w-screen">
						<Typography
							component={Routerlink}
							to={paths.root}
							variant="h6"
							style={{ color: "white" }}
						>
							Admin
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.destination}
							variant="h6"
							style={{ color: "white" }}
						>
							Destinations
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.category}
							variant="h6"
							style={{ color: "white" }}
						>
							Categories
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.categoryMapping}
							variant="h6"
							style={{ color: "white" }}
						>
							Mappings
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.format}
							variant="h6"
							style={{ color: "white" }}
						>
							Formats
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.iframeGenerator}
							variant="h6"
							style={{ color: "white" }}
						>
							Iframe generator
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.widgetGenerator}
							variant="h6"
							style={{ color: "white" }}
						>
							Widget generator
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.soulcircus}
							variant="h6"
							style={{ color: "white" }}
						>
							Soulcircus
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.bugReports}
							variant="h6"
							style={{ color: "white" }}
						>
							Bug reports
						</Typography>

						<Typography
							component={Routerlink}
							to={paths.product}
							variant="h6"
							style={{ color: "white" }}
						>
							Products
						</Typography>

						<Typography variant="h6">Environment: {currentEnvironment}</Typography>

						<IconButton color="inherit" aria-labelledby="logout" onClick={logout}>
							<LogoutIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>

			<Toolbar />
		</div>
	);
}

export default MainNav;
