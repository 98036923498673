import { z } from "zod";
import { currencyCodeSchema } from "../currencies";
import { practitionerFileSchema } from "./practitioner";

namespace SessionStatus {
	export const ACTIVE = "active";
	export const CANCELED = "canceled";
	export const REJECTED = "rejected";
	export const POSTED = "posted";
	export const PENDING = "pending";
	export const DRAFT = "draft";
	export const ERROR_POSTING = "error_posting";
	export const UNPAID = "unpaid";
}

export type SessionStatusOption =
	| typeof SessionStatus.ACTIVE
	| typeof SessionStatus.CANCELED
	| typeof SessionStatus.REJECTED
	| typeof SessionStatus.POSTED
	| typeof SessionStatus.PENDING
	| typeof SessionStatus.DRAFT
	| typeof SessionStatus.ERROR_POSTING
	| typeof SessionStatus.UNPAID;

export const SessionStatusEnum = z.enum([
	SessionStatus.ACTIVE,
	SessionStatus.CANCELED,
	SessionStatus.REJECTED,
	SessionStatus.POSTED,
	SessionStatus.PENDING,
	SessionStatus.DRAFT,
	SessionStatus.ERROR_POSTING,
	SessionStatus.UNPAID,
]);

namespace RSVPStatus {
	export const CLOSED = "closed";
	export const FULL = "full";
	export const OPEN = "open";
	export const DISABLED = "disabled";
}

export type RSVPStatusOption =
	| typeof RSVPStatus.CLOSED
	| typeof RSVPStatus.FULL
	| typeof RSVPStatus.OPEN
	| typeof RSVPStatus.DISABLED;

export const RSVPStatusEnum = z.enum([
	RSVPStatus.CLOSED,
	RSVPStatus.FULL,
	RSVPStatus.OPEN,
	RSVPStatus.DISABLED,
]);

namespace EventVenue {
	export const ONLINE = "online";
	export const BY_PHONE = "byPhone";
	export const ON_SITE = "onSite";
	export const HYBRID = "hybrid";
}

export type EventVenueOption =
	| typeof EventVenue.ONLINE
	| typeof EventVenue.BY_PHONE
	| typeof EventVenue.ON_SITE
	| typeof EventVenue.HYBRID;

export const EventVenueOptionList = [
	EventVenue.ON_SITE,
	EventVenue.ONLINE,
	EventVenue.HYBRID,
	EventVenue.BY_PHONE,
];

// can't directly pass EventVenueOptionList into zod, type issue
export const EventVenueOptionEnum = z.enum([
	EventVenue.ONLINE,
	EventVenue.BY_PHONE,
	EventVenue.ON_SITE,
	EventVenue.HYBRID,
]);

namespace Audience {
	export const PRIVATE = "private";
	export const COMPANY = "company";
	export const BOTH = "both";
}

export type AudienceOption =
	| typeof Audience.PRIVATE
	| typeof Audience.COMPANY
	| typeof Audience.BOTH;

export const AudienceOptionList = [
	Audience.PRIVATE,
	Audience.COMPANY,
	Audience.BOTH,
];

// can't directly pass AudienceOptionList into zod, type issue
export const AudienceOptionEnum = z.enum([
	Audience.PRIVATE,
	Audience.COMPANY,
	Audience.BOTH,
]);

export const BookingMethodList = [
	"timeSlot",
	"contactEmail",
	"externalPage",
] as const;

export const BookingMethodEnumSchema = z.enum(BookingMethodList);

namespace SessionSlotDisplayStatus {
	export const FULLY_BOOKED = "fully_booked";
	export const ARCHIVED = "archived";
	export const ALREADY_BOOKED_BY_USER = "already_booked_by_user";
	export const AVAILABLE = "available";
}

export type SessionSlotDisplayStatusOption =
	| typeof SessionSlotDisplayStatus.FULLY_BOOKED
	| typeof SessionSlotDisplayStatus.ARCHIVED
	| typeof SessionSlotDisplayStatus.ALREADY_BOOKED_BY_USER
	| typeof SessionSlotDisplayStatus.AVAILABLE;

export const SessionSlotDisplayStatusEnum = z.enum([
	SessionSlotDisplayStatus.FULLY_BOOKED,
	SessionSlotDisplayStatus.ARCHIVED,
	SessionSlotDisplayStatus.ALREADY_BOOKED_BY_USER,
	SessionSlotDisplayStatus.AVAILABLE,
]);

export const onlineLinkSchema = z.object({
	link: z.string().url().optional(),
	password: z.string().optional(),
});

export const addressSchema = z.object({
	address: z.string().min(1).optional(),
	city: z.string().min(1).optional(),
	country: z.string().min(1).optional(),
	coordinates: z
		.object({
			lat: z.number().optional(),
			lng: z.number().optional(),
		})
		.optional(),
});

export const otherPriceSchema = z.object({
	name: z.string(),
	fee: z.number().optional().default(0),
});

export const sessionSchema = z.object({
	isSessionDraft: z.boolean(),
	_id: z.string(),
	// Basic info
	practitionerId: z.string(),
	title: z.string(),
	audience: AudienceOptionEnum,
	description: z.string(),
	eventVenue: EventVenueOptionEnum,
	fee: z.number().optional().default(0),
	prices: z.array(otherPriceSchema).optional(),
	currency: currencyCodeSchema.optional().default("SEK"),
	maxNumberOfParticipants: z.number().optional().default(1),
	termsAndConditions: z.string().optional(),
	// in minutes
	duration: z.number().min(1),
	categories: z.string().array().min(1),
	minBookingLeadTime: z.number().min(0).optional().default(0),

	// booking
	bookingMethod: BookingMethodEnumSchema,
	externalPage: z.string().optional(),
	contactEmail: z.string().optional(),

	// Contact info
	// input by practitioner
	address: addressSchema.optional(),
	phone: z.string().optional(),

	// generated by code
	sessionUrl: z.string().optional(),

	// Showing material
	images: practitionerFileSchema.array().optional(),
	isFeatured: z.boolean().optional(),
	isFeaturedByAdmin: z.boolean().optional(),

	// if the session is online or hybrid, it may have a link to join
	onlineLink: onlineLinkSchema.optional(),
	onlinePhone: z.string().optional(),
	bookingViews: z.number().optional(),
});

export const simpleOrderSchema = z.object({
	orderId: z.string(),
	// order (paymentLog) includes userId,
	// put it here just for convenient that in frontend can quick check if the user has already booked this slot
	userId: z.string(),
});

export const sessionSlotSchema = z.object({
	_id: z.string(),

	startTime: z.date(),
	endTime: z.date(),
	successOrderList: simpleOrderSchema.array().optional().default([]),
	canceledOrderList: simpleOrderSchema.array().optional().default([]),
	onlineLink: onlineLinkSchema.optional(),
	onlinePhone: z.string().optional(),
	// bind to the session
	sessionId: z.string().optional(),
	createdAt: z.date(),
	updatedAt: z.date().optional(),
	status: SessionStatusEnum,
	rsvpStatus: RSVPStatusEnum,
});

export const displaySessionSlotSchema = sessionSlotSchema.extend({
	displayStatus: SessionSlotDisplayStatusEnum.default(
		SessionSlotDisplayStatus.AVAILABLE,
	),
});

export const createSessionWithSlotsSchema = z.object({
	session: sessionSchema.omit({ _id: true }),
	slots: z.array(sessionSlotSchema.omit({ _id: true })).optional(),
});

export const updateSessionWithSlotsSchema = z.object({
	session: sessionSchema,
	slots: z.array(sessionSlotSchema.partial()).optional(),
	oldSlots: z.array(sessionSlotSchema).optional(),
});

export const sessionWithSlotsSchema = z.object({
	session: sessionSchema,
	slots: z.array(sessionSlotSchema).optional(),
});

export const sessionEventDateSchema = z.object({
	_id: z.string().optional(),
	startDate: z.date(),
	endDate: z.date(),
	isRemoveable: z.boolean().optional(),
});

export type SimpleOrder = z.infer<typeof simpleOrderSchema>;
export type SessionEventDate = z.infer<typeof sessionEventDateSchema>;

export type Session = z.infer<typeof sessionSchema>;
export type SessionSlot = z.infer<typeof sessionSlotSchema>;
export type DisplaySessionSlot = z.infer<typeof displaySessionSlotSchema>;
export type CreateSessionWithSlots = z.infer<
	typeof createSessionWithSlotsSchema
>;
export type UpdateSessionWithSlots = z.infer<
	typeof updateSessionWithSlotsSchema
>;
export type SessionWithSlots = z.infer<typeof sessionWithSlotsSchema>;
export type BookingMethodEnum = z.infer<typeof BookingMethodEnumSchema>;
