import { z } from "zod";
import { currencyCodeSchema } from "../currencies";
import { latLngSchema, latitudeSchema, longitudeSchema } from "../destination";
import { tagEventSchema } from "../eventmanager";
import { AppLanguageCode, appLanguageCodeSchema } from "../languages";
import { rsvpSchema } from "../rsvp";
import { dateSchema } from "../utilities";
import {
	apiRatingSchema,
	L1,
	l1Schema,
	OpenWeekDay,
	OpenWeekDaySchema,
	Photo,
	availableApisSchema,
	photoSchema,
	reviewSchema,
	viatorDataSchema,
	videoSchema,
	recoApiSchema,
} from "./properties";
import { scraperRecoSchema } from "./scrapers";

export const baseRecoSchema = z.object({
	address: z.string().optional(),
	apiRatings: apiRatingSchema.array(),
	availableApis: recoApiSchema.array(),
	availableTranslations: appLanguageCodeSchema.array().optional(),

	bookProvider: availableApisSchema.optional(),
	bookUrl: z.string().optional(),

	categories: z.string().array().min(1),
	city: z.string().optional(),
	creator: z.string().optional(),
	currency: currencyCodeSchema.optional(),
	customBoost: z.number().optional(),

	description: z.string().optional(),
	destinationSlug: z.string(),
	displayComment: z.string().optional(),

	email: z.string().optional(),
	eventEnd: z.string().or(z.string().array().optional()).optional(),
	eventStart: z.string().optional().or(z.string().array().optional()),
	eventStartTimeSpecified: z.boolean().optional(),
	eventTargetGroup: z.string().array().optional(),
	eventVenueName: z.string().optional(),
	eventVenueDescription: z.string().optional(),

	features: z.string().array().optional(),
	formats: z.string().array().optional(),

	geometry: latLngSchema,

	hide: z.boolean().optional(),
	hideReason: z.string().optional(),
	hideEventEndTime: z
		.boolean()
		.optional()
		.describe(
			"Use this field when the event has no ending time. It will hide the end time on the guides",
		),
	hybrid: z.boolean().optional(),

	// In the backend, the model will be a number, but in the front, it will be a string
	// (Hash from the FullReco meta.id)
	id: z.string(),
	ignoreSkippableFilters: z.boolean().optional(),
	internationalPhoneNumber: z
		.string()
		.refine((phone) => phone?.slice(0, 1) === "+" || phone?.slice(0, 2) === "00")
		.optional(),
	isFree: z.boolean(),
	isSponsored: z.boolean().optional(),
	isPinned: z.boolean().optional(),

	languages: z.string().array().optional(),
	lastUpdated: z.string().optional(),
	label: z.string().optional(),
	logos: z.string().array().optional(),
	longTermEvent: z.boolean().optional(),
	l1: l1Schema,
	l2: z.string().array().optional(),

	menuUrl: z.string().optional(),

	name: z.string(),

	online: z.boolean(),
	openingHours: OpenWeekDaySchema.array().optional(),
	organizer: z.string().optional(),

	paymentMethods: z.string().array().optional(),
	phone: z.string().optional(),
	photos: photoSchema.array(),
	postalCode: z.string().optional(),
	postDate: dateSchema.optional(),
	price: z.number().optional(),
	priceProvider: z.string().optional(),
	priceRanges: z
		.array(
			z.object({
				minPrice: z.number(),
				maxPrice: z.number(),
				name: z.string(),
				currency: z.string().optional(),
			}),
		)
		.optional()
		.nullable(),
	publisher: z.string().optional(),
	publisherLogo: z.string().optional(),

	rating: z.number().gte(1).lte(5).optional(),
	region: z.string().optional(),
	reviews: reviewSchema.array(),
	reviewsUrl: z.string().url().optional(),
	rsvp: rsvpSchema.optional(),
	rsvpLink: z.string().url().optional(),

	shouldMatch: z.boolean(),

	tag: z.string(),
	taggingLabel: tagEventSchema.optional(),

	url: z.string().url().or(z.string().optional()).optional(),

	viatorData: viatorDataSchema.optional(),
	videos: videoSchema.array().optional(),

	websiteUrl: z.string().url().optional(),
});
export type BaseReco = z.infer<typeof baseRecoSchema>;
export type FeaturedReco = BaseReco & {
	displayComments: Record<AppLanguageCode, string>;
};

// I couldn't find a better way to specify as a record and define this as key and the merge it with the object
// Me neither
export const languageSpecific = z.object({
	da: baseRecoSchema.partial(),
	de: baseRecoSchema.partial(),
	en: baseRecoSchema.partial(),
	"en-AU": baseRecoSchema.partial(),
	"en-US": baseRecoSchema.partial(),
	es: baseRecoSchema.partial(),
	fr: baseRecoSchema.partial(),
	it: baseRecoSchema.partial(),
	nb: baseRecoSchema.partial(),
	nl: baseRecoSchema.partial(),
	pt: baseRecoSchema.partial(),
	ro: baseRecoSchema.partial(),
	ru: baseRecoSchema.partial(),
	sv: baseRecoSchema.partial(),
	zh_Hans: baseRecoSchema.partial(),
	zh_Hant: baseRecoSchema.partial(),
});
export const structuredRecoSchema = baseRecoSchema.merge(languageSpecific);
/**
 * Structured reco are the recos created by the users or converted from the Full recos. They contain all the data needed to be displayed.
 */
export type StructuredReco = z.infer<typeof structuredRecoSchema>;

// These are the types used in the backend
export type UnhashedBaseReco = Omit<BaseReco, "id"> & {
	id: number;
};
export type UnhashedStructuredReco = Omit<StructuredReco, "id"> & {
	id: number;
};

export const fullRecoSchema = z.object({
	meta: z.object({
		id: z.number(),
		destinationSlug: z.string(),
		geoPoint: latLngSchema.optional(),
		name: z.string(),
		shouldMatch: z.boolean(),
	}),
	/**
	 * Reco created from an event in the EM
	 */
	crunchoEvent: scraperRecoSchema.optional(),
	/**
	 * Reco created in the CMS
	 */
	crunchoReco: scraperRecoSchema.optional(),
	datatourismReco: scraperRecoSchema.optional(),
	forkReco: scraperRecoSchema.optional(),
	foursquareReco: scraperRecoSchema.optional(),
	googleReco: scraperRecoSchema.optional(),
	hotelscombinedReco: scraperRecoSchema.optional(),
	opendatasoftReco: scraperRecoSchema.optional(),
	osemediaReco: scraperRecoSchema.optional(),
	ringbilletReco: scraperRecoSchema.optional(),
	scrapedEvent: scraperRecoSchema.optional(),
	tripadvisorReco: scraperRecoSchema.optional(),
	vastReco: scraperRecoSchema.optional(),
	viatorReco: scraperRecoSchema.optional(),
	yelpReco: scraperRecoSchema.optional(),
});

/**
 * Full reco are the recos created by the scrapers. They contain all the raw data from the different sources and can not be used as is.
 */
export type FullReco = z.infer<typeof fullRecoSchema>;

/**
 * Object with `lat` and `lon`
 * We need this because ES needs `lon` not `lng`
 * TODO?: Maybe we should use `lat` and `lon` everywhere
 */
export const latLonSchema = z.object({
	lat: latitudeSchema,
	lon: longitudeSchema,
});
export type LatLon = z.infer<typeof latLonSchema>;

/**
 * Recommendation returned while getting matches from Elastisearch
 */
export interface MatchReco {
	address?: string;
	destinationSlug: string;

	eventStart?: string | string[];
	eventEnd?: string | string[];

	geometry?: LatLon;

	id: number;

	l1: L1;

	name: string;

	shouldMatch: boolean;
}

export interface SearchReco {
	apisCount: number;
	availableApis: string[];
	address?: string;

	bookUrl?: string;
	bookableApisCount: number;

	categories: string[];
	city?: string;
	commentsCount: number;
	creator?: string;
	customBoost?: number;

	dateWeightedScore: number;
	description?: string;
	destinationSlug: string;
	displayComment?: string;

	eventEnd?: string | string[];
	existRecentComment: boolean;
	eventStart?: string | string[];
	eventVenueName?: string;

	formats?: string[];

	geometry?: LatLon;

	hasPrice: boolean;
	hide: boolean;
	hideReason?: string;

	id: number;
	ignoreSkippableFilters?: boolean;
	isBookable: boolean;
	isFree: boolean;
	isHotel: boolean;
	isOnline: boolean;
	isPinned?: boolean;
	isRestaurant: boolean;
	isSponsored: boolean;

	l1: L1;
	l2?: string[];
	label?: string;
	lastUpdated?: string;

	menuUrl?: string;

	name: string;

	openingHours?: OpenWeekDay[];
	organizer?: string;

	phoneNumberAvailable: boolean;
	photos?: Photo[];
	photosCount: number;
	postDate?: Date;
	price?: number;

	rankingsCount: number;
	rating?: number;
	ratings: { api: string; rating?: number; createdAt?: string }[];
	ratingFork: number;
	ratingFoursquare: number;
	ratingGoogle: number;
	ratingViator: number;
	ratingYelp: number;
	reviewsUrl?: string;

	tag?: string;
	translatedNames: { [key in AppLanguageCode]?: string };

	videosCount: number;

	websiteAvailable: boolean;
}

export type ActiveSectionFilters = { slug: string; displayName: string }[];

export type FeatureKey =
	| "Accommodation"
	| "AccueilPaysan"
	| "AccueilVelo"
	| "AdditionalServicesBooking"
	| "AirConditioner"
	| "ATM"
	| "AubergeDeVillage"
	| "AudioGuide"
	| "Auditorium"
	| "BabyChair"
	| "BabyClub"
	| "BabyEquipment"
	| "BabySitter"
	| "BadmintonCourt"
	| "Bar"
	| "Barbecue"
	| "BasketballCourt"
	| "Bathing"
	| "Beach"
	| "BeachClub"
	| "BeachVolleyballCourt"
	| "Bedroom"
	| "BienvenueaLaFerme"
	| "BikeGarage"
	| "BikeHire"
	| "BikeParking"
	| "BikeRoom"
	| "BikeTransport"
	| "BilliardOrPoolTable"
	| "BoulesPitch"
	| "Box"
	| "BreadDepot"
	| "Bungalow"
	| "BungalowRental"
	| "BungalowTentRental"
	| "BycicleCleaningEquipment"
	| "CableSatellite"
	| "CamperPitch"
	| "CamperServicePoint"
	| "CamperVanServiceArea"
	| "CampingQualite"
	| "CanalPlus"
	| "CanoeBase"
	| "CaravanRental"
	| "CarPark"
	| "Casino"
	| "Caterer"
	| "CateringOnSite"
	| "ChargingStation"
	| "ChemicalToiletEmptying"
	| "Cinema"
	| "CityBreakConfort"
	| "CityBreakPremium"
	| "ClefVerte"
	| "Cloakroom"
	| "CoachParking"
	| "CoffeeMaker"
	| "CommunityRoom"
	| "ConferenceRoom"
	| "Container"
	| "Cot"
	| "Court"
	| "CoveredCarPark"
	| "Covers"
	| "CurrencyExchangeOffice"
	| "DanceFloor"
	| "Dishwasher"
	| "DocumentationPlan"
	| "DogShower"
	| "Dormitory"
	| "DoubleGlazing"
	| "DrinkingWaterFaucet"
	| "DriveIn"
	| "DVDPlayer"
	| "EbikeChargingStation"
	| "EcolabelEuropeen"
	| "ElectricalConnections"
	| "Elevator"
	| "EnclosedLand"
	| "EntertainmentLocation"
	| "EquippedMeetingRoom"
	| "ExternalFacilitiesBooking"
	| "FacilitiesForDisabled"
	| "FamillePlus"
	| "FinalHousekeeping"
	| "Fireplace"
	| "FleursDeSoleil"
	| "Floor"
	| "FoodGrocery"
	| "FootballField"
	| "Freezer"
	| "Fridge"
	| "GameArea"
	| "GameRoom"
	| "GamesLibrary"
	| "Garage"
	| "GarageOrIndoorParkCar"
	| "Garden"
	| "GardenLounge"
	| "GasDepot"
	| "GitesDeFrance"
	| "GolfCourse"
	| "HairDryer"
	| "Hammam"
	| "HealthHeated"
	| "HeatedPool"
	| "HeatedTerrace"
	| "Heating"
	| "HebergementPeche"
	| "Home"
	| "HomeDeliveries"
	| "Ice"
	| "InclosedTerrace"
	| "IndoorPool"
	| "InternetAccess"
	| "IronAndIroningBoard"
	| "IroningRoom"
	| "Jacuzzi"
	| "KidsClub"
	| "KitchenKitchenette"
	| "Laundry"
	| "LeisureCentre"
	| "Library"
	| "LinenHire"
	| "LivingRoom"
	| "LockedStorageRoom"
	| "LogisRestaurantException"
	| "LogisRestaurantGourmand"
	| "LogisRestaurantSavoureux"
	| "LogisRestaurantTerroir"
	| "LuggageRoomOrLocker"
	| "LuggageTransport"
	| "MaisonDesIllustres"
	| "MaitreCuisinierDeFrance"
	| "MaitreRestaurateur"
	| "MarkedTrails"
	| "Massages"
	| "MealDelivery"
	| "MeetingRoom"
	| "Microwave"
	| "MiniBar"
	| "MiniGolf"
	| "MobilHomeHire"
	| "MonumentHistorique"
	| "MultimediaLibrary"
	| "MultimediaRoom"
	| "MuseeDeFrance"
	| "NaturalZoneOfEcologicalInterestFaunaAndFlora"
	| "Nightclub"
	| "NightReception"
	| "Nightwatch"
	| "Nursery"
	| "OneStorey"
	| "OutdoorCarPark"
	| "OutdoorPool"
	| "OutstandingGarden"
	| "Oven"
	| "Paddling"
	| "ParcNaturelRegional"
	| "Park"
	| "PavillonBleu"
	| "PetitesCitesDeCaractere"
	| "Phone"
	| "PianoBar"
	| "PicnicArea"
	| "PicnicRoom"
	| "PicnicTables"
	| "PingPongTable"
	| "Pitch"
	| "Playground"
	| "PlusBeauxDetoursDeFrance"
	| "PrivateDiningRoom"
	| "PrivateDryer"
	| "PrivateGarage"
	| "PrivateParking"
	| "PrivatePool"
	| "PrivateShower"
	| "PrivateTerrace"
	| "PrivateWasher"
	| "ProjectionRoom"
	| "PublicToilets"
	| "QualiteTourisme"
	| "ReceptionRoom"
	| "ReducedAccess"
	| "RelaxationArea"
	| "ReserveNaturelleNationale"
	| "ReserveNaturelleRegionale"
	| "Restaurant"
	| "RestaurateurDeQualite"
	| "Room"
	| "RoomService"
	| "RVSite"
	| "Safe"
	| "Sauna"
	| "SelfService"
	| "Seminar"
	| "SeminarRoom"
	| "SemiShaddedArea"
	| "SeparateEntrance"
	| "SeparateGarden"
	| "SeparateToilet"
	| "ShaddedArea"
	| "SharedBathroom"
	| "SharedDryer"
	| "SharedGarden"
	| "SharedPool"
	| "SharedShower"
	| "SharedWasher"
	| "SheetsAndTowels"
	| "Shelter"
	| "Shop"
	| "ShopsFirstNeed"
	| "Shower"
	| "ShuttleAirportOrRailwayStation"
	| "ShuttleForAdditionalActivities"
	| "SiteClosedAtNight"
	| "SmokingArea"
	| "SnackAndDrinksBar"
	| "Solarium"
	| "SouvenirSales"
	| "SportRoom"
	| "SportsEquipmentRental"
	| "SquashCourt"
	| "StationVerte"
	| "Stereo"
	| "StorageArea"
	| "SupervisedBeach"
	| "SwimmingPool"
	| "Swing"
	| "TableDeTerroir"
	| "TableDistinguee"
	| "TableGastronomique"
	| "TablesEtAubergesDeFrance"
	| "TakeawayFoodOrMeals"
	| "TakeAwayLunchBox"
	| "TastingArea"
	| "TeenagersClub"
	| "TelephoneAccess"
	| "Television"
	| "TennisCourt"
	| "TentHire"
	| "Terrace"
	| "TerraceBalcony"
	| "Toboggan"
	| "Toilet"
	| "TourismeHandicapPictogrammeAuditif"
	| "TourismeHandicapPictogrammeMental"
	| "TourismeHandicapPictogrammeMoteur"
	| "TourismeHandicapPictogrammeVisuel"
	| "TourismLocation"
	| "TouristInformation"
	| "TradeShows"
	| "TVRoomArea"
	| "TvSocket"
	| "Vacuum"
	| "Valet"
	| "VideoRoom"
	| "VignoblesDecouvertes"
	| "VilleOuPaysDartEtDhistoire"
	| "VolleyballCourt"
	| "Washrooms"
	| "WastwaterEvacuation"
	| "WaterConnections"
	| "WaterToboggan"
	| "Wifi"
	| "WindsurfRental"
	| "Wintering";
