import { z } from "zod";

namespace DiscountType {
	export const FIXED = "fixed";
	export const PERCENTAGE = "percentage";
}

export type DiscountTypeOption =
	| typeof DiscountType.FIXED
	| typeof DiscountType.PERCENTAGE;

export const DiscountTypeEnum = z.enum([
	DiscountType.FIXED,
	DiscountType.PERCENTAGE,
]);

export const DiscountTypeOptionList = [
	DiscountType.FIXED,
	DiscountType.PERCENTAGE,
];

export const couponSchema = z.object({
	_id: z.string(),
	practitionerId: z.string(),
	name: z.string(),
	code: z.string().regex(/^[A-Z0-9]{2,10}$/, {
		message: "Code must be 2-10 uppercase alphanumeric characters",
	}),
	discountType: DiscountTypeEnum,
	discountValue: z.number(),
	usageLimit: z.number(),
	usageCount: z.number(),
	startDate: z.string().optional(),
	endDate: z.string().optional(),
	isActive: z.boolean().default(true),
	usedBy: z.array(z.string()), // track the users who have used this code so that each user can only use this code once
	eligibleSessions: z.array(
		z.object({
			_id: z.string(),
			title: z.string(),
		}),
	),
});

export const getCouponCreateSchema = (t: (key: string) => string) =>
	z.object({
		name: z.string(),
		code: z.string().regex(/^[A-Z0-9]{2,10}$/, {
			message: t("Code must be 2-10 uppercase alphanumeric characters"),
		}),
		discountType: DiscountTypeEnum,
		discountValue: z.number(),
		usageLimit: z.number(),
		usageCount: z.number(),
		startDate: z.string().optional(),
		endDate: z.string().optional(),
		eligibleSessions: z.array(
			z.object({
				_id: z.string(),
				title: z.string(),
			}),
		),
	});

export const couponCreateSchema = z.object({
	name: z.string(),
	code: z.string().regex(/^[A-Z0-9]{2,10}$/, {
		message: "Code must be 2-10 uppercase alphanumeric characters",
	}),
	discountType: DiscountTypeEnum,
	discountValue: z.number(),
	usageLimit: z.number(),
	usageCount: z.number(),
	startDate: z.string().optional(),
	endDate: z.string().optional(),
	eligibleSessions: z.array(
		z.object({
			_id: z.string(),
			title: z.string(),
		}),
	),
});

export const initialCouponValues = couponCreateSchema.parse({
	name: "",
	code: "EXAMPLE123",
	discountType: "fixed",
	discountValue: 0,
	usageLimit: 1,
	usageCount: 0,
	isActive: true,
	eligibleSessions: [],
});

export type Coupon = z.infer<typeof couponSchema>;
export type CreateCoupon = z.infer<typeof couponCreateSchema>;
