import {
	Destination,
	PopulatedDestination,
} from "@cruncho/cruncho-shared-types";
import { APIBase } from "./APIBase";

/**
 * A class handling the interactions with destination-related endpoints in the api
 */
export class APIDestination extends APIBase {
	/**
	 * Download the list of all destinations
	 * Careful, it returns a City, not a dbCity
	 * You will need to remove the extrakeys if you want to perform the conversion
	 * @returns the list of destinations
	 */
	async getAll() {
		const response = await this.api.get<Destination[]>("/destination");
		return response.data;
	}

	/**
	 * Download a destination using an id
	 * Careful, it returns a City, not a dbCity
	 * You will need to remove the extrakeys if you want to perform the conversion
	 * @returns a destination of given id
	 */
	async GetById(id: string) {
		const response = await this.api.get<Destination>(`/destination/${id}`);
		return response.data;
	}

	/**
	 * Download the list of all destinations, then get the venus associated with each
	 * Makes the whole process a bit slow, especially when the cache has been cleared, but
	 * this is the admin so it is not very important
	 * @returns the list of destinations with their venues
	 */
	async getAllWithVenues() {
		const destinations = await this.getAll();

		const destinatinationsWithVenues = await Promise.all(
			destinations.map(
				async (destination) =>
					(
						await this.api.get<PopulatedDestination>(
							`/destination/${destination._id}`,
						)
					).data,
			),
		);

		return destinatinationsWithVenues;
	}

	/**
	 * Fully update one destination having the given uid
	 * Careful, the backend returns a city and not a DatabaseCiy
	 * @returns the updated destination
	 */
	async update(_id: string, destination: Destination): Promise<Destination> {
		const response = await this.api.put<Destination>(
			`/destination/${_id}`,
			destination,
		);

		return response.data;
	}

	/**
	 * Create a new destination (only in database, no integration with AWS, GOOGLE )
	 * @return the created destination
	 */
	async create(destination: Destination) {
		const response = await this.api.post<Destination>(
			"/destination",
			destination,
		);
		return response.data;
	}
}
